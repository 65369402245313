import React, {useContext} from 'react';
import './agb.scss';
import Footer from '../../components/footer/Footer';
import NavbarWeb from "../../components/navbarWeb/NavbarWeb";
import Container from '@mui/material/Container';
import { agbData } from '../../source/agbData';
import { LanguageContext } from "../../context/LanguageContext";


function AGB() {

  const { language } = useContext(LanguageContext);


  return (
    <div className="datenschutz">
        <NavbarWeb scrolled={true}/>
        <div className="datenschutzContainer">
        <Container maxWidth="lg" >
          <h1>{agbData.agb.title[language]}</h1>
          <p>{agbData.agb.title_desc1[language]}</p>

          <h2>{agbData.agb.subtitle1[language]}</h2>
          <p>{agbData.agb.subtitle1_desc1[language]}</p>

          <h2>{agbData.agb.subtitle2[language]}</h2>
          <p className="space-bottom"><b>2.1</b> {agbData.agb.subtitle2_desc1[language]}</p>
          <p className="space-bottom"><b>2.2</b> {agbData.agb.subtitle2_desc2[language]}</p>
          <p><b>2.3</b> {agbData.agb.subtitle2_desc3[language]}</p>

          <h2>{agbData.agb.subtitle3[language]}</h2>
          <p>{agbData.agb.subtitle3_desc1[language]}</p>

          <h2>{agbData.agb.subtitle4[language]}</h2>
          <p><b>{agbData.agb.subtitle4_desc1[language]}</b></p>
          <p className="space-bottom">{agbData.agb.subtitle4_desc2[language]}</p>
          <p><b>{agbData.agb.subtitle4_desc3[language]}</b></p>
          <p className="space-bottom">{agbData.agb.subtitle4_desc4[language]}</p>
          <p><b>{agbData.agb.subtitle4_desc5[language]}</b></p>
          <p>{agbData.agb.subtitle4_desc6[language]}</p>
          <p>• {agbData.agb.subtitle4_desc7[language]}</p>
          <p>• {agbData.agb.subtitle4_desc8[language]}</p>
          <p className="space-bottom">{agbData.agb.subtitle4_desc9[language]}</p>
          <p><b>{agbData.agb.subtitle4_desc10[language]}</b></p>
          <p>{agbData.agb.subtitle4_desc11[language]}</p>
          <p>{agbData.agb.subtitle4_desc12[language]}</p>
          <p className="space-bottom">{agbData.agb.subtitle4_desc13[language]}</p>
          <p><b>{agbData.agb.subtitle4_desc11[language]}</b></p>
          <p>{agbData.agb.subtitle4_desc12[language]}</p>
          <p className="space-bottom">{agbData.agb.subtitle4_desc13[language]}</p>

          <p><b>{agbData.agb.subtitle4_desc14[language]}</b></p>
          <p>{agbData.agb.subtitle4_desc15[language]}</p>
          <p className="space-bottom">{agbData.agb.subtitle4_desc16[language]}</p>
          <p className="space-bottom"><b>4.4</b> {agbData.agb.subtitle4_desc17[language]}</p>
          <p className="space-bottom"><b>{agbData.agb.subtitle4_desc18[language]}</b></p>
          <p><b>{agbData.agb.subtitle4_desc19[language]}</b></p>
          <p>• <b>{agbData.agb.subtitle4_desc20[language]}</b> {agbData.agb.subtitle4_desc21[language]}</p>
          <p>• <b>{agbData.agb.subtitle4_desc22[language]}</b> {agbData.agb.subtitle4_desc23[language]}</p>
          <p>• <b>{agbData.agb.subtitle4_desc24[language]}</b> {agbData.agb.subtitle4_desc25[language]}</p>
          <p className="space-bottom">{agbData.agb.subtitle4_desc26[language]}</p>
          <p><b>{agbData.agb.subtitle4_desc27[language]}</b></p>
          <p className="space-bottom">{agbData.agb.subtitle4_desc28[language]}</p>
          <p><b>{agbData.agb.subtitle4_desc29[language]}</b></p>
          <p>{agbData.agb.subtitle4_desc30[language]}</p>

          <h2>{agbData.agb.subtitle5[language]}</h2>
          <p className="space-bottom"><b>5.1</b> {agbData.agb.subtitle5_desc1[language]}</p>
          <p className="space-bottom"><b>5.2</b> {agbData.agb.subtitle5_desc2[language]}</p>
          <p><b>5.3</b> {agbData.agb.subtitle5_desc3[language]}</p>


          <h2>{agbData.agb.subtitle6[language]}</h2>
          <p><b>{agbData.agb.subtitle6_desc1[language]}</b></p>
          <p className="space-bottom">{agbData.agb.subtitle6_desc2[language]}</p>
          <p><b>{agbData.agb.subtitle6_desc3[language]}</b></p>
          <p>{agbData.agb.subtitle6_desc4[language]}</p>
          <p>• {agbData.agb.subtitle6_desc5[language]}</p>
          <p>• {agbData.agb.subtitle6_desc6[language]}</p>
          <p>{agbData.agb.subtitle6_desc7[language]}</p>
          <p className="space-bottom">{agbData.agb.subtitle6_desc8[language]}</p>
          <p><b>{agbData.agb.subtitle6_desc9[language]}</b></p>
          <p>{agbData.agb.subtitle6_desc10[language]}</p>
          <p>• {agbData.agb.subtitle6_desc11[language]}</p>
          <p>• {agbData.agb.subtitle6_desc12[language]}</p>
          <p>{agbData.agb.subtitle6_desc13[language]}</p>
          <p>{agbData.agb.subtitle6_desc14[language]}</p>

          <h2>{agbData.agb.subtitle7[language]}</h2>
          <p>{agbData.agb.subtitle7_desc1[language]}</p>

          <h2>{agbData.agb.subtitle8[language]}</h2>
          <p>{agbData.agb.subtitle8_desc1[language]}</p>

          <h2>{agbData.agb.subtitle9[language]}</h2>
          <p>{agbData.agb.subtitle9_desc1[language]}</p>
          <p>{agbData.agb.subtitle9_desc2[language]}</p>
          <p>• {agbData.agb.subtitle9_desc3[language]}</p>
          <p>• {agbData.agb.subtitle9_desc4[language]}</p>
          <p>• {agbData.agb.subtitle9_desc5[language]}</p>
          <p>{agbData.agb.subtitle9_desc6[language]}</p>
          <p>• {agbData.agb.subtitle9_desc7[language]}</p>
          <p>• {agbData.agb.subtitle9_desc8[language]}</p>

          <h2>{agbData.agb.subtitle10[language]}</h2>
          <p><b>{agbData.agb.subtitle10_desc1[language]}</b></p>
          <p>{agbData.agb.subtitle10_desc2[language]}</p>
          <p className="space-bottom">{agbData.agb.subtitle10_desc3[language]}</p>
          <p><b>{agbData.agb.subtitle10_desc4[language]}</b></p>
          <p className="space-bottom">{agbData.agb.subtitle10_desc5[language]}</p>
          <p><b>{agbData.agb.subtitle10_desc6[language]}</b></p>
          <p>{agbData.agb.subtitle10_desc7[language]}</p>
          <p>{agbData.agb.subtitle10_desc8[language]}</p>

          <h2>{agbData.agb.subtitle11[language]}</h2>
          <p>{agbData.agb.subtitle11_desc1[language]}</p>


          <h2>{agbData.agb.subtitle12[language]}</h2>
          <p className="space-bottom"><b>12.1</b> {agbData.agb.subtitle12_desc1[language]}</p>
          <p className="space-bottom"><b>12.2</b> {agbData.agb.subtitle12_desc2[language]}</p>
          <p><b>12.3</b> {agbData.agb.subtitle12_desc3[language]}</p>

          <h2>{agbData.agb.subtitle13[language]}</h2>
          <p>{agbData.agb.subtitle13_desc1[language]}</p>

          <h2>{agbData.agb.subtitle14[language]}</h2>
          <p>{agbData.agb.subtitle14_desc1[language]}</p>

          <h2>{agbData.agb.subtitle15[language]}</h2>
          <p className="space-bottom"><b>15.1</b> {agbData.agb.subtitle15_desc1[language]}</p>
          <p><b>15.2</b> {agbData.agb.subtitle15_desc2[language]}</p>

        </Container>
        </div>
        <Footer/>
    </div>    
  )
}

export default AGB