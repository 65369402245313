export const agbData = {
  agb: {
    title: {
      DE: "Allgemeine Geschäftsbedingungen (AGB)",
      EN: "General Terms and Conditions (GTC)",
    },
    subtitle1: {
      DE: "1. Geltungsbereich",
      EN: "1. Scope",
    },
    subtitle2: {
      DE: "2. Vertragsschluss",
      EN: "2. Conclusion of Contract",
    },
    subtitle3: {
      DE: "3. Registrierungspflichten",
      EN: "3. Registration Obligations",
    },
    subtitle4: {
      DE: "4. Beförderungsvertrag",
      EN: "4. Transport Contract",
    },
    subtitle5: {
      DE: "5. Wartezeiten",
      EN: "5. Waiting Times",
    },
    subtitle6: {
      DE: "6. No Show",
      EN: "6. No-Show",
    },
    subtitle7: {
      DE: "7. Änderungen des Angebots",
      EN: "7. Changes to the Offer",
    },
    subtitle8: {
      DE: "8. Haftung des Nutzers",
      EN: "8. User Liability",
    },
    subtitle9: {
      DE: "9. Verhalten in der Limousine",
      EN: "9. Conduct in the Limousine",
    },
    subtitle10: {
      DE: "10. Vergütung und Zahlung",
      EN: "10. Compensation and Payment",
    },
    subtitle11: {
      DE: "11. Stundenbuchungen im Umkreis von München",
      EN: "11. Hourly Bookings within the Munich Area",
    },
    subtitle12: {
      DE: "12. Haftung",
      EN: "12. Liability",
    },
    subtitle13: {
      DE: "13. Datenschutz",
      EN: "13. Data Protection",
    },
    subtitle14: {
      DE: "14. Salvatorische Klausel",
      EN: "14. Severability Clause",
    },
    subtitle15: {
      DE: "15. Schlussbestimmungen",
      EN: "15. Final Provisions",
    },
    title_desc1: {
        DE: "Stand: Unterschleißheim, Januar 2024",
        EN: "As of: Unterschleißheim, January 2024",
    },
    subtitle1_desc1: {
      DE: "Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für sämtliche Leistungen der VIP Driver‘s GmbH, einschließlich Chauffeur- und Transferdiensten. Sie finden Anwendung auf alle Buchungen, die über die Website, per E-Mail, persönlich oder telefonisch vorgenommen werden. Abweichende Bedingungen des Kunden gelten nur, wenn diese ausdrücklich und schriftlich von der VIP Driver‘s GmbH bestätigt wurden.",
      EN: "These General Terms and Conditions (GTC) apply to all services provided by VIP Driver's GmbH, including chauffeur and transfer services. They are valid for all bookings made through the website, by email, in person, or by phone. Deviating terms from the customer apply only if explicitly and in writing confirmed by VIP Driver's GmbH.",
    },
    subtitle2_desc1: {
      DE: "Ein Vertrag kommt zustande, wenn der Kunde eine Buchung abschließt und diese von der VIP Driver‘s GmbH schriftlich oder elektronisch bestätigt wird.",
      EN: "A contract is concluded when the customer completes a booking, and it is confirmed in writing or electronically by VIP Driver's GmbH.",
    },
    subtitle2_desc2: {
      DE: "Der Kunde ist verpflichtet, korrekte und vollständige Angaben zu machen, insbesondere zu Abhol- und Zielorten, Personenzahl und Sonderwünschen.",
      EN: "The customer is required to provide correct and complete information, especially regarding pick-up and destination locations, number of people, and special requests.",
    },
    subtitle2_desc3: {
      DE: "Änderungen oder Ergänzungen sind nur verbindlich, wenn sie von der VIP Driver‘s GmbH schriftlich bestätigt wurden.",
      EN: "Changes or additions are only binding if they have been confirmed in writing by VIP Driver's GmbH.",
    },
    subtitle3_desc1: {
      DE: "Der Nutzer ist verpflichtet, korrekte und vollständige Angaben bei der Registrierung oder Buchung zu machen. Automatisierte Registrierungen oder Buchungen sind nicht zulässig.",
      EN: "The user is required to provide correct and complete information during registration or booking. Automated registrations or bookings are not allowed.",
    },
    subtitle4_desc1: {
      DE: "4.1 Fahrarten und Leistungsänderungen",
      EN: "4.1 Types of Rides and Service Changes",
    },
    subtitle4_desc2: {
      DE: "Der Nutzer kann zwischen verschiedenen Fahrarten wählen, wie Transfers, Langstreckenfahrten und Stundenbuchungen.",
      EN: "The user can choose from various types of rides, such as transfers, long-distance trips, and hourly bookings.",
    },
    subtitle4_desc3: {
      DE: "Transferbuchungen / Langstreckenfahrten:",
      EN: "Transfer Bookings / Long-Distance Trips:",
    },
    subtitle4_desc4: {
      DE: "Transferbuchungen beinhalten Fahrten zwischen einem festgelegten Start- und Zielort. Der Preis basiert auf der im Buchungsprozess festgelegten Strecke, ohne Berücksichtigung zusätzlicher Kilometer, sofern die Route unverändert bleibt.",
      EN: "Transfer bookings include trips between a specified starting and destination point. The price is based on the route defined during the booking process, without considering additional kilometers, as long as the route remains unchanged.",
    },
    subtitle4_desc5: {
      DE: "Stundenbuchungen:",
      EN: "Hourly Bookings:",
    },
    subtitle4_desc6: {
      DE: "Stundenbuchungen sind zeitbasierte Buchungen, bei denen das Fahrzeug und der Chauffeur für einen bestimmten Zeitraum zur Verfügung stehen.",
      EN: "Hourly bookings are time-based reservations where the vehicle and chauffeur are available for a specified period.",
    },
    subtitle4_desc7: {
      DE: "Im Buchungsprozess ist eine festgelegte Anzahl an Freikilometern enthalten.",
      EN: "A specified number of free kilometers are included in the booking process.",
    },
    subtitle4_desc8: {
      DE: "Überschreiten die tatsächlich gefahrenen Kilometer die Freikilometer, werden zusätzliche Kilometer gemäß der aktuellen Preisliste berechnet.",
      EN: "If the actual kilometers driven exceed the free kilometers, additional kilometers will be charged according to the current price list.",
    },
    subtitle4_desc9: {
      DE: "Änderungen an Fahrtmodalitäten sind nach Verfügbarkeit möglich, können jedoch zu zusätzlichen Kosten führen.",
      EN: "Changes to ride modalities are possible subject to availability, but may result in additional costs.",
    },
    subtitle4_desc10: {
      DE: "4.2 Abholzeit und Verzögerungen",
      EN: "4.2 Pick-up Time and Delays",
    },
    subtitle4_desc11: {
      DE: "Die Abholzeit wird in der Buchungsbestätigung festgelegt.",
      EN: "The pick-up time is set in the booking confirmation.",
    },
    subtitle4_desc12: {
      DE: "Bei Flughafen- oder Bahnhofsabholungen wird die Abholzeit entsprechend angepasst, wenn korrekte Flug- oder Zugnummern angegeben wurden.",
      EN: "For airport or train station pick-ups, the pick-up time will be adjusted accordingly if correct flight or train numbers are provided.",
    },
    subtitle4_desc13: {
      DE: "Sehr kurzfristige Änderungen der Abholzeit (unter 1 Stunde vor der geplanten Abholzeit) können nicht mehr berücksichtigt werden. In diesem Fall wird die ursprünglich geplante Abholzeit eingehalten, und die Wartezeit wird voll in Rechnung gestellt.",
      EN: "Very short notice changes to the pick-up time (less than 1 hour before the scheduled pick-up) can no longer be accommodated. In this case, the originally scheduled pick-up time will be adhered to, and the waiting time will be fully charged.",
    },
    subtitle4_desc14: {
      DE: "4.3 Fahrzeugklasse",
      EN: "4.3 Vehicle Class",
    },
    subtitle4_desc15: {
      DE: "Der Nutzer kann aus verschiedenen Fahrzeugklassen wählen. Die angezeigten Fahrzeuge dienen lediglich der Veranschaulichung und garantieren kein bestimmtes Modell.",
      EN: "The user can choose from different vehicle classes. The displayed vehicles are for illustration purposes only and do not guarantee a specific model.",
    },
    subtitle4_desc16: {
      DE: "VIP Driver‘s GmbH behält sich vor, kostenfreie Upgrades durchzuführen.",
      EN: "VIP Driver's GmbH reserves the right to offer complimentary upgrades.",
    },
    subtitle4_desc17: {
      DE: "Sonderwünsche (z. B. Kindersitze, spezielles Gepäck) sind bei der Buchung anzugeben, andernfalls kann die Erfüllung dieser Wünsche nicht garantiert werden.",
      EN: "Special requests (e.g., child seats, special luggage) must be specified during booking; otherwise, fulfillment of these requests cannot be guaranteed.",
    },
    subtitle4_desc18: {
      DE: "4.5 Stornierungen und Umbuchungen",
      EN: "4.5 Cancellations and Rebookings",
    },
    subtitle4_desc19: {
      DE: "Stornierungen",
      EN: "Cancellations",
    },
    subtitle4_desc20: {
      DE: "Stundenbuchungen:",
      EN: "Hourly Bookings:",
    },
    subtitle4_desc21: {
      DE: "Stornierungen sind bis 24 Stunden vor der Abholzeit kostenfrei",
      EN: "Cancellations are free of charge up to 24 hours before the pick-up time.",
    },
    subtitle4_desc22: {
      DE: "Transferbuchungen:",
      EN: "Transfer Bookings:",
    },
    subtitle4_desc23: {
      DE: "Stornierungen sind bis 1,5 Stunden vor der Abholzeit kostenfrei.",
      EN: "Cancellations are free of charge up to 1.5 hours before the pick-up time.",
    },
    subtitle4_desc24: {
      DE: "Langstreckenfahrten (ab 150 km):",
      EN: "Long-Distance Trips (from 150 km):",
    },
    subtitle4_desc25: {
      DE: "Stornierungen sind bis 12 Stunden vor der Abholzeit kostenfrei.",
      EN: "Cancellations are free of charge up to 12 hours before the pick-up time.",
    },
    subtitle4_desc26: {
      DE: "Bei späterer Stornierung wird der volle Preis berechnet.",
      EN: "For later cancellations, the full price will be charged.",
    },
    subtitle4_desc27: {
      DE: "Umbuchungen:",
      EN: "Rebookings:",
    },
    subtitle4_desc28: {
      DE: "Umbuchungen gelten als Neubuchungen und unterliegen den gleichen Bedingungen wie die ursprüngliche Buchung.",
      EN: "Rebookings are considered as new bookings and are subject to the same terms as the original booking.",
    },
    subtitle4_desc29: {
      DE: "4.6 Beförderungssicherheit",
      EN: "4.6 Transport Safety",
    },
    subtitle4_desc30: {
      DE: "Gepäck, Sondergepäck (z. B. Rollstühle, Tiere) und Kindersitze müssen im Voraus angemeldet werden. Der Chauffeur kann die Beförderung verweigern, wenn die angegebenen Anforderungen nicht erfüllt sind.",
      EN: "Luggage, special luggage (e.g., wheelchairs, pets), and child seats must be registered in advance. The chauffeur may refuse transport if the specified requirements are not met.",
    },

    subtitle5_desc1: {
      DE: "Eine kostenlose Wartezeit von bis zu 15 Minuten ist bei allen Buchungen inbegriffen. Bei Flughafen- und Bahnhofabholungen beträgt die kostenlose Wartezeit jedoch 60 Minuten.",
      EN: "A free waiting time of up to 15 minutes is included in all bookings. For airport and train station pickups, the free waiting time is 60 minutes.",
    },
    subtitle5_desc2: {
      DE: "Nach Ablauf der kostenlosen Wartezeit werden zusätzliche Minuten berechnet. Die kostenlose Wartezeit wird ab diesem Punkt nicht mehr berücksichtigt.",
      EN: "After the free waiting time has expired, additional minutes will be charged. The free waiting time will no longer be considered from this point.",
    },
    subtitle5_desc3: {
      DE: "Wartezeiten werden in 15-Minuten-Takten berechnet.",
      EN: "Waiting times are calculated in 15-minute increments.",
    },
    subtitle6_desc1: {
      DE: "NICHT ANGETRETENE FAHRTEN OHNE ABSAGE, VERSPÄTUNGEN DES NUTZERS",
      EN: "NO-SHOWS WITHOUT CANCELLATION, USER DELAYS",
    },
    subtitle6_desc2: {
      DE: "Allgemein: Bei nicht angetretenen Fahrten ohne Absage erlischt der Beförderungsanspruch des Nutzers, nicht jedoch der Vergütungsanspruch der VIP Driver‘s GmbH.",
      EN: "General: In case of no-shows without cancellation, the user's transport entitlement expires, but the VIP Driver's GmbH's entitlement to payment remains.",
    },
    subtitle6_desc3: {
      DE: "Transfer- und Langstreckenfahrten:",
      EN: "Transfer and long-distance trips:",
    },
    subtitle6_desc4: {
      DE: "Fahrt gilt als nicht angetreten, wenn der Nutzer ohne Absage:",
      EN: "The trip is considered a no-show if the user fails to cancel and:",
    },
    subtitle6_desc5: {
      DE: "An normalen Abholorten: Nicht innerhalb von 30 Minuten nach der vereinbarten Zeit erscheint.",
      EN: "At regular pickup locations: Does not appear within 30 minutes of the agreed time.",
    },
    subtitle6_desc6: {
      DE: "An Flughäfen/Bahnhöfen: Nicht innerhalb von 60 Minuten erscheint.",
      EN: "At airports/train stations: Does not appear within 60 minutes.",
    },
    subtitle6_desc7: {
      DE: "Die Fahrt wird voll berechnet; Wartezeitzuschläge entfallen.",
      EN: "The trip will be fully charged; waiting time surcharges will not apply.",
    },
    subtitle6_desc8: {
      DE: "Abweichungen gelten nur bei telefonischer Absprache eines späteren Abholzeitpunkts.",
      EN: "Deviations are only valid if a later pickup time is agreed upon by phone.",
    },
    subtitle6_desc9: {
      DE: "Stundenbuchungen:",
      EN: "Hourly bookings:",
    },
    subtitle6_desc10: {
      DE: "Fahrt gilt als nicht angetreten, wenn der Nutzer ohne Absage:",
      EN: "The trip is considered a no-show if the user fails to cancel and:",
    },
    subtitle6_desc11: {
      DE: "Nicht nach Ablauf der gebuchten Stunden am Abholort erscheint.",
      EN: "Does not appear at the pickup location after the booked hours have expired.",
    },
    subtitle6_desc12: {
      DE: "Die Fahrt wird voll berechnet.",
      EN: "The trip will be fully charged.",
    },
    subtitle6_desc13: {
      DE: "Abweichungen gelten nur bei telefonischer Absprache eines späteren Abholzeitpunkts.",
      EN: "Deviations are only valid if a later pickup time is agreed upon by phone.",
    },
    subtitle6_desc14: {
      DE: "Ein Anspruch auf Änderung des Abholzeitpunkts besteht grundsätzlich nicht.",
      EN: "There is generally no entitlement to change the pickup time.",
    },
    subtitle7_desc1: {
        DE: "VIP Drivers behält sich das Recht vor, das Angebot der Plattform oder die AGB jederzeit zu ändern.",
        EN: "VIP Drivers reserves the right to change the platform's offerings or the terms and conditions at any time."
    },
    subtitle8_desc1: {
        DE: "Der Nutzer haftet für Schäden, die durch Missachtung der Verhaltensvorgaben (z. B. Rauchen im Fahrzeug) entstehen.",
        EN: "The user is liable for damages caused by violating the behavioral guidelines (e.g., smoking in the vehicle)."
    },
    subtitle9_desc1: {
        DE: "Der Chauffeur ist für die sichere Durchführung der Fahrt verantwortlich.",
        EN: "The chauffeur is responsible for the safe execution of the trip."
    },
    subtitle9_desc2: {
        DE: "Den Fahrgästen ist untersagt:",
        EN: "Passengers are prohibited from:"
    },
    subtitle9_desc3: {
        DE: "Türen während der Fahrt zu öffnen.",
        EN: "Opening doors during the trip."
    },
    subtitle9_desc4: {
        DE: "Gegenstände aus dem Fahrzeug zu werfen.",
        EN: "Throwing objects from the vehicle."
    },
    subtitle9_desc5: {
        DE: "Körperteile herauszuragen oder aus dem Fahrzeug zu schreien.",
        EN: "Sticking body parts out or shouting from the vehicle."
    },
    subtitle9_desc6: {
        DE: "Gerätebedienung: Nur nach Einweisung durch den Chauffeur.",
        EN: "Device operation: Only after instructions from the chauffeur."
    },
    subtitle9_desc7: {
        DE: "Rauchen: Im Fahrgastraum verboten. Bei Verstoß trägt der Nutzer die Kosten für Reinigung und Nutzungsausfall.",
        EN: "Smoking: Forbidden in the passenger compartment. In case of violation, the user bears the costs for cleaning and downtime."
    },
    subtitle9_desc8: {
        DE: "Speisen und Getränke: Mitgebrachte Speisen sind unerwünscht. Alkoholische Getränke nur nach vorheriger Absprache erlaubt.",
        EN: "Food and drinks: Bringing food is undesirable. Alcoholic beverages are allowed only with prior agreement."
    },
    subtitle10_desc1: {
        DE: "10.1 Preise und Zuschläge",
        EN: "10.1 Prices and Surcharges"
    },
    subtitle10_desc2: {
        DE: "Der in der Buchungsbestätigung angegebene Preis beinhaltet die vereinbarte Beförderungsleistung.",
        EN: "The price specified in the booking confirmation includes the agreed transportation service."
    },
    subtitle10_desc3: {
        DE: "Zusätzliche Kosten können entstehen, z. B. bei Zwischenstopps, Übergepäck, Sonderwünschen, Wartezeiten und extra Kilometern.",
        EN: "Additional costs may arise, e.g. for stops, excess baggage, special requests, waiting times, and extra kilometers."
    },
    subtitle10_desc4: {
        DE: "10.2 Mahngebühren",
        EN: "10.2 Reminder Fees"
    },
    subtitle10_desc5: {
        DE: "Für verspätete Zahlungen oder fehlgeschlagene Abbuchungen können Mahngebühren oder Bearbeitungsgebühren anfallen.",
        EN: "Reminder or processing fees may apply for late payments or failed charges."
    },
    subtitle10_desc6: {
        DE: "10.3 Zahlung und Verzugszinsen",
        EN: "10.3 Payment and Default Interest"
    },
    subtitle10_desc7: {
        DE: "Zahlungen erfolgen per Kreditkarte, Banküberweisung oder anderen akzeptierten Zahlungsmethoden.",
        EN: "Payments are made via credit card, bank transfer, or other accepted payment methods."
    },
    subtitle10_desc8: {
        DE: "Bei verspäteter Zahlung behält sich die VIP Driver‘s GmbH vor, Verzugszinsen in gesetzlicher Höhe zu berechnen.",
        EN: "In case of late payment, VIP Driver’s GmbH reserves the right to charge default interest at the statutory rate."
    },
    subtitle11_desc1: {
        DE: "Sollte die Fahrt außerhalb des Umkreises von München beginnen oder enden, werden zusätzlich die An- oder Rückfahrtkosten berechnet.",
        EN: "If the ride begins or ends outside the Munich area, additional travel costs will be charged."
    },
    subtitle12_desc1: {
        DE: "Die VIP Driver‘s GmbH haftet bei Schäden nur für Vorsatz und grobe Fahrlässigkeit.",
        EN: "VIP Driver’s GmbH is only liable for damages in cases of intent or gross negligence."
    },
    subtitle12_desc2: {
        DE: "Bei einfacher Fahrlässigkeit haftet die VIP Driver‘s GmbH nur bei Verletzung wesentlicher Vertragspflichten (Kardinalpflichten).",
        EN: "In cases of simple negligence, VIP Driver’s GmbH is only liable for breach of essential contractual obligations (cardinal duties)."
    },
    subtitle12_desc3: {
        DE: "Für Verspätungen oder Ausfälle aufgrund höherer Gewalt, wie Verkehr, Wetterbedingungen oder Flugverspätungen, wird keine Haftung übernommen.",
        EN: "No liability is assumed for delays or cancellations due to force majeure, such as traffic, weather conditions, or flight delays."
    },
    subtitle13_desc1: {
        DE: "Die VIP Driver‘s GmbH verarbeitet personenbezogene Daten gemäß den geltenden Datenschutzgesetzen. Weitere Informationen entnehmen Sie bitte der Datenschutzerklärung auf unserer Website.",
        EN: "VIP Driver’s GmbH processes personal data in accordance with applicable data protection laws. For more information, please refer to the privacy policy on our website."
    },
    subtitle14_desc1: {
        DE: "Sollten einzelne Bestimmungen dieser AGB unwirksam oder nicht durchsetzbar sein oder Lücken enthalten, bleiben die übrigen Regelungen wirksam. Die Parteien verpflichten sich, die unwirksamen, undurchführbaren oder fehlenden Bestimmungen durch solche zu ersetzen, die dem Sinn und wirtschaftlichen Zweck sowie dem Willen der Parteien am nächsten kommen.",
        EN: "If any provisions of these terms and conditions are invalid or unenforceable or contain gaps, the remaining provisions shall remain in effect. The parties agree to replace the invalid, unenforceable, or missing provisions with those that most closely reflect the meaning, economic purpose, and intent of the parties."
    },
    subtitle15_desc1: {
        DE: "Es gilt das Recht der Bundesrepublik Deutschland.",
        EN: "The law of the Federal Republic of Germany applies."
    },
    subtitle15_desc2: {
        DE: "Gerichtsstand ist der Sitz der VIP Driver‘s GmbH, sofern der Kunde Kaufmann oder eine juristische Person des öffentlichen Rechts ist.",
        EN: "The place of jurisdiction is the registered office of VIP Driver’s GmbH, provided the customer is a merchant or a legal entity under public law."
    }
  },
};
