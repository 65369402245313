import Home from "./pages/Home/home";
import Karriere from "./pages/Karriere/karriere";
import Kontakt from "./pages/Kontakt/kontakt";
import Bewerbung from "./pages/Karriere/bewerbung";
import Buchung from "./pages/Buchung/buchung";
import Impressum from "./pages/Impressum/impressum";
import Datenschutz from "./pages/Datenschutz/datenschutz";
import AGB from "./pages/AGB/agb";
import {React, useState} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {SourceContext} from './context/SourceContext';
import {DestinationContext} from './context/DestinationContext'
import { DateTimeContext } from "./context/DateTimeContext";
import { LoadScript } from '@react-google-maps/api';
import dayjs from 'dayjs';
import { DurationContext } from "./context/DurationContext";
import { TransportArtContext } from "./context/TransportArt";
import { SelectedCarContext } from "./context/SelectedCarContext";
import { RabattcodeContext } from "./context/RabattcodeContext";
import { DistanceContext } from "./context/DistanceContext";
import { DurationForDistanceContext } from "./context/DurationForDistance";
import { PriceContext } from "./context/PriceContext";
import Cookiebanner from "./components/cookiebanner/cookiebanner";
import ScrollToTop from "./components/scrolltotop/scrolltotop";
import Leistungen from "./pages/Leistungen/leistungen";


function App() {
  const [source, setSource] = useState("");
  const [destination, setDestination] = useState("");
  const [datetime, setDateTime] = useState(dayjs().add(3, 'hour'));
  const [duration, setDuration] = useState(40);
  const [transportArt, setTransportArt] = useState(0);
  const [selectedCar, setSelectedCar] = useState("Business Class");
  const [rabattcode, setRabattcode] = useState("");
  const [distance, setDistance] = useState(0);
  const [durationForDistance, setDurationForDistance] = useState(0);

  const updatedPriceContext = {
    price1: 0,
    price2: 0
  };
  const [priceContext, setPriceContext] = useState(updatedPriceContext);

  const libraries = ['places'];

  return (
    <LoadScript libraries={libraries} googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
    <DurationForDistanceContext.Provider value={{durationForDistance, setDurationForDistance}}>
    <DistanceContext.Provider value={{distance, setDistance}}>
    <RabattcodeContext.Provider value={{rabattcode, setRabattcode}}>
    <SelectedCarContext.Provider value={{selectedCar, setSelectedCar}}>
    <TransportArtContext.Provider value={{transportArt, setTransportArt}}>
    <DurationContext.Provider value={{duration, setDuration}}>
    <DateTimeContext.Provider value={{datetime, setDateTime}}>
    <SourceContext.Provider value={{source, setSource}}>
    <DestinationContext.Provider value={{destination, setDestination}}>
    <PriceContext.Provider value={{priceContext, setPriceContext}}>
    <div className="app">
      <BrowserRouter>
        <ScrollToTop/>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/leistungen" element={<Leistungen />} />
            <Route path="/karriere" element={<Karriere />} />
            <Route path="/buchung" element={<Buchung />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/bewerbung" element={<Bewerbung />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/agb" element={<AGB />} />
        </Routes>
      </BrowserRouter>
      <Cookiebanner/>
    </div>
    </PriceContext.Provider>
    </DestinationContext.Provider>
  </SourceContext.Provider>
  </DateTimeContext.Provider>
  </DurationContext.Provider>
  </TransportArtContext.Provider>
  </SelectedCarContext.Provider>
  </RabattcodeContext.Provider>
  </DistanceContext.Provider>
  </DurationForDistanceContext.Provider>

  </LoadScript>
  );
}

export default App;